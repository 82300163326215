/* Define variables with the syntax --var-name: value. Commonly done on :root (= <html>), but possible on any element */
:root {
  /* font settings */
  --family: "Inter", sans-serif;

  /* Theme */
  /* --background-colour-light: #c6c5a7; */
  --background-colour-light: #e2e2e1;
  --background-colour-dark: #131313;
  --text-colour-lighter: #dcdcdc;
  --text-colour-light: #ffffff;
  --text-colour-dark: #424242;
}

body {
  font-family: var(--family);
  color: var(--text-colour-lighter);
  scrollbar-color: #696969 var(--background-colour-dark);
  /* background : radial-gradient(rgba(133, 128, 80, .2) 1px, transparent 1px);
  background-size : 17px 17px; */
  background-color: var(--background-colour-dark);
}

/*=============================================
=            Splashscreen            =
=============================================*/

.splash {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--background-colour-dark);
  transition: 1s;
}

.logo-header {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--text-colour-lighter);
}

.logo {
  position: relative;
  font-weight: 800;
  font-size: 1.8em;
  display: flex;
  top: -20px;
  opacity: 0%;
}

.logo-text {
  flex-direction: column;
  font-style: italic;
  padding: 0;
  line-height: 30px;
}

.logo-green {
  color: #84804d;
}

.logo.active {
  top: 0;
  opacity: 1;
  transition: ease-in-out 0.5s;
}

.logo.fade {
  bottom: 150px;
  opacity: 0;
  transition: ease-in-out 0.5s;
}

/*=====  End of Section comment block  ======*/

/*=============================================
=            Dot Grid            =
=============================================*/

#dotCanvas {
  position: absolute;
  padding: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: block;
  margin: 0;
  z-index: -1;
  pointer-events: none;
}

/*=====  End of Dot Grid  ======*/

/*=============================================
=            Gobal CSS           =
=============================================*/

.container {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y proximity;
  scroll-behavior: smooth;
}

.panel {
  height: 100vh; /* Make each section take up the full height of the screen */
  scroll-snap-align: start;
  overflow-x: hidden;
}

.content-panel {
  display: flex;
  
}

.content-panel h2 {
  font-size: calc(3vw + 2.5vh);
  font-weight: 800;
  line-height: 1em;
}

.content-panel h3 {
  margin-top: 4vh;
  font-size: calc((3vw + 2.5vh) / 2);
  font-weight: 400;
}

.content-panel h4 {
  font-size: calc((3vw + 2.5vh) / 4);
  font-weight: 200;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-content {
  display: flex;
  align-items: center;
  max-height: 100vh;
  width: 46vw;
  padding-bottom: 100px;
  padding-left: 50px;
}

.image-content img {
  border-radius: 50px;
  height: 85vh;
  width: 40vw;
  object-fit: cover;
}

.text-content {
  display: flex;
  margin-top: 2vh;
  width: 50vw;
}

.text-width {
  max-width: 44vw;
  padding-top: 2.5em;
}

.text-content p {
  margin-top: 5px;
  font-size: clamp(0.8em, calc(0.8vw + 0.8vh), 2em);
  line-height: 40px;
  margin-top: 4vh;
}

.panel:not(:first-child).panel:not(:last-child):nth-child(odd) {
  flex-direction: row-reverse;
}

.skip-link {
  background: black;
  position: absolute;
  left: 0.5rem;
  transform: translateY(-120%);
  padding: 0.5rem 1.5rem;
  border-radius: 0 0 0.25rem 0.25rem;
  transition: transform 325 ease-in;
}

.screen-reader-text {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.skip-link:focus {
  transform: translateY(0);
}

/*----------  Sticky Header  ----------*/

.sticky-header {
  position: sticky;
  display: flex;
  top: 0;
  width: 100%;
  height: 100px;
  background-color: var(--background-colour-dark);
  transition: all 100ms ease-in-out;
  align-items: center;
  text-decoration: none;
  font-weight: 100;
  z-index: 5;
  border-bottom: 4px solid rgb(14, 14, 14);
}

.sticky-header button {
 
  border: 0;
  background-color: var(--background-colour-dark);
  color: white;
  cursor: pointer;
  font-size: clamp(0.6em, 0.8vw, 1.0em);
}

button:hover {
  color:#83824E;
  border-color: #83824E;
  transition: 0.5s;
}
.home-btn:hover {
  color:#ffffff;
  background-color: #131313;
  border-color: #ffffff;
  transition: 0.5s;
}

.sticky-header ul {
  display: flex;
  list-style: none;
  margin: auto;
}
.sticky-header ul li {
  margin: clamp(7px, 15px, 25px);
  align-self: center;
}

.sticky-header ul li a:visited {
  text-decoration: none;
  color: var(--text-colour-lighter);
}

.pad {
  /* padding-top: 100px; */
  scroll-margin: 100px 0 0 0px;
}

.line {
  width: 25px;
  background-color: rgb(255, 255, 255);
  height: 2px;
  border: 0;
  border-radius: 2px;
}

.small-line {
  width: 20px;
  background-color: rgb(255, 255, 255);
  margin-bottom: 20px;
}

.sticky-logo {
  width: 160px;
  height: 80px;
}

.footer-logo {
  width: 160px;
  height: 80px;
}

/*----------  Home Nav CSS  ----------*/

.home-nav {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 0;
  width: 100%;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  background-color: var(--background-colour-dark);
  align-items: center;
  z-index: 6;
  list-style: none;
}


/* nav animation */
.home-nav[data-state="opened"] {
  height: 100vh;
  width: 100%;
}

.home-nav[data-state="closed"] {
  height: 0;
}

.home-nav-container {
  position: absolute;
  display: flex;
  right: 1vh;
  top: 1vh;
  flex-direction: row;
  height: 100px;
  align-items: center;
}

.menu-btn {
  display: flex;
  cursor: pointer;
  /* align-items: center; */
  min-width: 60px;
  min-height: 20px;
  z-index: 7;
  border: 0;
  background: transparent;
  color: var(--text-colour-light);
  --button-color: var(--text-colour-light);
}


#home .home-nav-container button {
  font-weight: 200;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  color: var(--text-colour-light);
  font-size: clamp(1em, calc(0.7vw + 0.7vh), 2em);
  transition-duration: 0.5s;
}

#home .home-nav-container button:hover {
  color: #83824E;
}


.vertical-line {
  display: inline-block;
  border-left: 0.6px solid #ccc;
  margin: 0 10px;
  height: 35px;
}
/*----------  Button animations  ----------*/

.menu-btn[data-state="closed"] :is(.top, .bottom) {
  animation: to-open-icon 1s forwards;
}

.menu-btn[data-state="opened"] :is(.top, .bottom) {
  animation: to-close-icon 1s forwards;
}
.menu-btn .hamburger {
  transition: rotate 800ms 100ms;
}
.menu-btn[data-state="opened"] .hamburger {
  rotate: 1turn;
}

.menu-btn .line.top {
  --rotation: -45deg;
  transform-origin: 65px 45px;
}
.menu-btn .line.bottom {
  --rotation: 45deg;
  transform-origin: 60px 55px;
}

@keyframes to-close-icon {
  0% {
    stroke-dashoffset: 0;
  }
  40% {
    stroke-dashoffset: 79.9;
  }
  60% {
    stroke-dashoffset: 79.9;
    rotate: calc(var(--rotation));
  }
  100% {
    stroke-dashoffset: 0;
    rotate: var(--rotation);
  }
}
@keyframes to-open-icon {
  0% {
    stroke-dashoffset: 0;
    rotate: var(--rotation);
  }
  40% {
    stroke-dashoffset: 79.9;
    rotate: var(--rotation);
  }
  60% {
    stroke-dashoffset: 79.9;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

/*----------  Fullsize Nav  ----------*/

.nav-btn {
  border: 0;
  background-color: var(--background-colour-dark);
  color: var(--text-colour-light);
  cursor: pointer;
}

/*----------  Page animations  ----------*/

.hidden {
  position: relative;
  opacity: 0;
  filter: blur(5px);
  transform: translate(var(--animation-side));
  transition: all 0.8s;
  transition-delay: calc(100ms * var(--order));
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translate(0);
}

/*=====  End of Gobal CSS   ======*/

/*=============================================
=            Home Page CSS            =
=============================================*/

#home {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("/images/images9.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#home::before {
  content: "";
  display: block;
  position: absolute;
  /* backdrop-filter: blur(2px); */
  width: 100%;
  height: 100vh;
  top: 0;
}

.home {
  position: absolute;
  flex-direction: column;
  align-items: center;
  display: flex;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  text-align: center;
}

.home h2 {
  font-size: calc(1vw + 2vh);
  font-weight: 600;
  color: var(--text-colour-light);
  /* transform:skew(-4deg); */
}

.home-center {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.home-scroll {
  margin-top: 16vh;
}

.home-btn {
  margin-top: 20px;
  font-size: 1.5em;
  font-weight: 700;
  cursor: pointer;
  color: var(--text-colour-light);
  background-color: rgba(0, 0, 0, 0);
  border-color: var(--text-colour-light);
  border-radius: 10px;
  width: 180px;
  height: 60px;
}

.home-logo {
  position: relative;
  margin: 1vh;
  width: fit-content;
}

#home h1,
#home p {
  display: flex;
  flex-direction: column;
  text-align: center;
}

#home h1 {
  font-size: calc(4vw + 3vh);
  font-weight: 800;
  line-height: 0.9;
  transform: skew(-7deg);
  -webkit-filter: drop-shadow(2px 2px rgb(0, 0, 0));
  filter: drop-shadow(2px 2px rgb(0, 0, 0));
}

#home p {
  font-size: clamp(0.8em, calc(0.8vw + 0.8vh), 2em);
  align-items: center;
}
#home .home-nav p {
  font-size: clamp(0.5em, calc(0.5vw + 0.5vh), 1em);
  align-items: center;
}

.home-text {
  padding-top: 10px;
  width: 700px;
  color: var(--text-colour-light);
}

.h1-highlight {
  /* color: var(--text-colour-dark); */
  background-image: linear-gradient(45deg, #a8a349, #726c00);
  /* background-image: -webkit-linear-gradient(45deg, #A8A349, #726C00); */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

/*=====  End of Home Page CSS  ======*/

/*=============================================
=               About Us Page                =
=============================================*/

#about p {
  margin-top: 4vh;
}

/*=====  End of About Us Page  ======*/

/*=============================================
=            Contact Section            =
=============================================*/

#contact {
  display: grid;
  justify-content: center;
  width: 100%;
  scroll-margin: 0;
}
#contact h2 {
  justify-self: center;
  margin-top: 10vh;
  font-size: calc(3vw + 2.5vh);
  font-weight: 800;
  line-height: 1em;
}

#contact p {
  font-size: calc((3vw + 2.5vh) / 4);
  text-align: center;
}
.contact-form {
  display: grid;
  margin: 0 auto;
  padding-bottom: 50px;
  height: 80vh;
  width: 500px;
  border-radius: 10px;
  text-align: center;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.form-group label {
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  border-radius: 5px;
  /* background-color: var(--background-colour-dark); */
  border: 1px solid #ccc;
  font-size: 1em;
}

input::placeholder {
  font-weight: bold;
  color: var(--text-colour-dark);
}

textarea::placeholder {
  font-weight: bold;
  color: var(--text-colour-dark);
}

.form-group textarea {
  min-height: 120px;
  resize: none;
}

.submit-btn {
  border-radius: 5px;
  background-color: var(--background-colour-dark);
  width: 200px;
  color: #fff;
  font-size: 1em;
  cursor: pointer;
  margin: 0 auto;
}

/*=====  End of Contact Section  ======*/

/*=============================================
=            Footer Section            =
=============================================*/
.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  width: 100vw;
  height: 100%;
  align-items: center;
  padding: 1rem;
  background-color: rgb(24, 24, 24);
  /* border-top: 4px solid rgb(14, 14, 14); */
  
}

form{ 
  padding-top: 50px;
}
#contact .footer-container p {
  font-size: 1em;
  color: rgb(95, 95, 95);
}

.footer-text{
  text-align: center;
  margin-top: 10px;
}

#contact .footer-container button {
  margin-bottom: 5px;
  font-size: 1em;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  color: var(--text-colour-light);
  border: 0;
}

/*=====  End of Footer Section  ======*/


/*=============================================
=                Media Queries               =
=============================================*/

/* Extra small devices (phones, up to 576px) */
@media (max-width: 576px) {

  /*=============================================
=                 Splashscreen               =
=============================================*/

.logo {

  font-weight: 800;
  font-size: 1.4em;
}

.logo img{
  min-width: 120px;
}

.logo-text {
  line-height: 24px;
}

/*=====      End of Splashscreen      ======*/


/*=============================================
=                Home Section              =
=============================================*/

.home-logo{
  padding-top: 10px;
  max-width: 180px;
}

.home{
  max-width: 100vw;
  height: 80vh;
}


.home-scroll{
  margin-top: auto;
  margin-bottom: 20px;
}
.home-scroll img {
  max-height: 60px;
}

.home-btn {
width: 100px;
height: 40px;
font-size: .8em;
}



/*=====  End of     Home Section    ======*/

/*=============================================
=                Sticky Header              =
=============================================*/

.secondary-nav-btn{
  display: none;
}
.sticky-header ul{
  display: none;
}

.sticky-header {
  display: flex;
}

.menu-btn{
  min-width: 40px;
  min-height: 40px;
  margin-left: auto;
  margin-right: 10px;
}

/*=====  End of Sticky Header   ======*/


/*=============================================
=                Main Content                =
=============================================*/
.panel:not(:first-child).panel:not(:last-child):nth-child(2n+1) {
  flex-direction: column;
}
.panel {
  flex-direction: column;
}
.panel .content-panel {
  height: 50vh;
}

.image-content{
  width: 100vw;
  display: inline-block;
  padding-bottom: 0;
  padding-left: 0;
}
.image-content img{
  height: 250px;
  width: 100vw;
  border-radius: 0;
}
.text-content{
  width: 100vw;
  text-align: center;

}
.text-content p {
  line-height: 25px;
  text-align: left;
}

#number-center {
  text-align: center;
}

.text-width {
  margin: auto;
  max-width: 95vw;
  padding-top: 0;
}

.line {
  margin: auto;
}

/*=====      End of Main Content      ======*/


/*=============================================
=            Contact            =
=============================================*/

#contact {
  display: grid;
  justify-content: center;
  width: 100%;
  scroll-margin: 0;
}
#contact h2 {
  justify-self: center;
  margin-top: 10vh;
  font-size: calc(3vw + 2.5vh);
  font-weight: 800;
  line-height: 1em;
}

#contact p {
  font-size: calc((3vw + 2.5vh) / 3);
  text-align: center;
}
.contact-form {
  display: grid;
  margin: 0 auto;
  padding-bottom: 20px;
  height: 80vh;
  width: 80vw;
  border-radius: 10px;
}

.form-group {
  margin-bottom: 0;
}

.submit-btn {
  margin-top: 20px;
  max-height: 60px;
}

input {
  max-height: 40px;
}


/*=====  End of Contact  ======*/


/*=============================================
=            Footer            =
=============================================*/

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  width: 100vw;
  height: 100%;
  align-items: center;
  padding: 1rem;
  background-color: rgb(24, 24, 24);
  /* border-top: 4px solid rgb(14, 14, 14); */
  
}

form{ 
  padding-top: 20px;
}
#contact .footer-container p {
  font-size: .8em;
  color: rgb(95, 95, 95);
}

.footer-text{
  text-align: center;
  margin-top: 5px;
}

#contact .footer-container button {
  margin-bottom: 5px;
  font-size: .8em;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  color: var(--text-colour-light);
  border: 0;
}

.footer-logo{
    width: 100px;
    height: 40px;
}

/*=====  End of Footer  ======*/



 
}

/* Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 576px) {
  /* Styles for small devices go here */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  /* Styles for medium devices go here */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  /* Styles for large devices go here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* Styles for extra large devices go here */
}


/*=====      End of Media Queries      ======*/


